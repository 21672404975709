Util.createCPObject("cpm.ecommerce.basket");

cpm.ecommerce.basket = {
    init: function() {
        $(".btnRemoveFromCart").click(function(e) {
            cpm.ecommerce.basket.removeFromCart.call(this, e);
        });

        $(".btnConfirmOrder").click(function(e) {
            cpm.ecommerce.basket.confirmOrder.call(this, e);
        });

        $("input[name=payment_method]").click(function() {
            var value = $(this).val();
            if(value == "Credit Card" || value == "Stripe" || value == "ElasticEngine"){
                $('.creditCardInfo').show();
                $('.paypalInfo').hide();
            } else if(value == "Paypal") {
                $('.creditCardInfo').hide();
                $('.paypalInfo').show();
            } else {
                $('.creditCardInfo').hide();
                $('.paypalInfo').hide();
            }
        });

        $("select[name=qty]").change(function() {
            var qty = $(this).val();
            var basket_id = $(this)
                .closest("td")
                .attr("basket_id");
            if (!qty || qty == 0) {
                Util.alert("Please enter a valid quantity");
            } else {
                var url =
                    "/index.php?module=ecommerce_basket&_spAction=updateCart&showHTML=0";
                Util.showProgressInd();
                $.getJSON(url, { basket_id: basket_id, qty: qty }, function(
                    json
                ) {
                    document.location = document.location;
                });
            }
        });

        $("#fld_card_type").change(function() {
            var value = $(this).val();
            if (value == "Amex") {
                $(".fld_amex_code").css("display", "block");
            } else {
                $(".fld_amex_code").css("display", "none");
            }
        });
    },

    removeFromCart: function(e) {
        e.preventDefault();
        Util.showProgressInd();
        var basketId = $(this).attr("basketId");
        var url = "/index.php?module=ecommerce_basket&_spAction=removeFromCart";
        $.get(url, { basketId: basketId }, function(json) {
            document.location = document.location;
        });
    },

    confirmOrder: function(e) {
        e.preventDefault();
        Util.showProgressInd();
        if (cpt.generic.isLoggedIn()) {
            var url =
                "/index.php?module=ecommerce_order&_spAction=confirmOrder";
            $.getJSON(url, function(json) {
                document.location = json.confirmOrderUrl;
            });
        }
    }
};
