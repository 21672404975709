var Globals = {};

var cpm = {}; // global object for modules
var cpp = {}; // global object for plugins
var cpw = {}; // global object for widgets
var cpt = {}; // global object for themes

//------------------------------------------------//
var Util = {
  createCPObject: function (objName) {
    var arr = objName.split(".");

    var objStr = "";
    $(arr).each(function (i) {
      var objNameTemp = arr[i];
      var objName = objStr + objNameTemp;
      eval("obj = " + objName);
      if (obj == undefined) {
        eval(objName + " = {}");
      }
      objStr += objNameTemp + ".";
    });
  },

  showProgressInd: function (message) {
    $("body").addClass("loading");
  },

  hideProgressInd: function () {
    $("body").removeClass("loading");
  },

  closeAllModals: function () {
    $(".modal").modal("hide");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
    $(".modal").remove();
  },

  alert: function (message, callbackOnClose) {
    if ($("#bsVersion").length > 0 && $("#bsVersion").val() >= 5) {
      var modalId = "cp-alert";
      if ($("#" + modalId).length > 0) {
        $("#" + modalId).remove();
      }

      var htmlString =
        "\
            <div id='cp-alert' class='modal' tabindex='-1'>\
                <div class='modal-dialog'>\
                    <div class='modal-content'>\
                        <div class='modal-body'>\
                            " +
        message +
        "\
                        </div>\
                        <div class='modal-footer'>\
                            <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>Close</button>\
                        </div>\
                    </div>\
                </div>\
            </div>\
            ";

      $("body").append(htmlString);

      var myModal = document.getElementById(modalId);

      var myModalInstance = new bootstrap.Modal(document.getElementById(modalId), {});
      myModalInstance.show();

      myModal.addEventListener("hidden.bs.modal", function () {
        if (callbackOnClose) {
          callbackOnClose.call(this);
        }
      });
    } else {
      if ($("#cp-alert").length > 0) {
        $("#cp-alert").modal("hide");
      }

      var htmlString =
        "\
            <div id='cp-alert' class='modal fade' tabindex='-1'>\
                <div class='modal-dialog'>\
                    <div class='modal-content'>\
                        <div class='modal-body'>\
                            <div class='modal-body'>\
                                " +
        message +
        "\
                            </div>\
                            <div class='modal-footer'>\
                                <button type='button' class='btn btn-primary' \
                                    data-dismiss='modal'>Close</button>\
                            </div>\
                        </div>\
                    </div>\
                </div>\
            </div>\
            ";
      $("body").append(htmlString);

      $("#cp-alert")
        .modal({
          keyboard: true,
        })
        .on("shown.bs.modal", function (e) {
          $(this).find(".modal-footer button").focus();
        });

      $("#cp-alert").on("hidden.bs.modal", function () {
        $(this).remove();
        if (callbackOnClose) {
          callbackOnClose.call(this);
        }
      });
    }
  },

  confirm: function (message, callback, options) {
    var settings = jQuery.extend(
      {
        btn1Label: "Yes",
        btn2Label: "Cancel",
        title: "",
        callbackCancel: null,
      },
      options
    );

    var title = settings.title;
    var btn1Label = settings.btn1Label;
    var btn2Label = settings.btn2Label;
    var focusedElm = $(":focus");

    // alert($('#bsVersion').val())
    console.log($("#bsVersion").length);
    console.log(parseInt($("#bsVersion").val()));
    if ($("#bsVersion").length > 0 && parseInt($("#bsVersion").val()) >= 5) {
      var modalId = "cpConfirm";
      if ($("#" + modalId).length > 0) {
        $("#" + modalId).remove();
      }

      var htmlString =
        "\
            <div id='cpConfirm' class='modal' tabindex='-1'>\
                <div class='modal-dialog'>\
                    <div class='modal-content'>\
                        <div class='modal-header'>\
                            <h5 class='modal-title'>" +
        title +
        "</h5>\
                            <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>\
                        </div>\
                        <div class='modal-body'>\
                            " +
        message +
        "\
                        </div>\
                        <div class='modal-footer'>\
                            <button type='button' class='btn btn-secondary' id='cpCancelDlg'>" +
        btn2Label +
        "</button>\
                            <button type='button' class='btn btn-primary' id='cpConfirmDlgSubmit'>" +
        btn1Label +
        "</button>\
                        </div>\
                    </div>\
                </div>\
            </div>\
            ";

      $("body").append(htmlString);

      var myModal = document.getElementById(modalId);

      var myModalInstance = new bootstrap.Modal(document.getElementById(modalId), {});
      myModalInstance.show();

      // confirm button
      $("#cpConfirmDlgSubmit").on("click", function () {
        callback(true);
        $("#" + modalId).modal("hide");
      });

      // cancel button
      $("#cpCancelDlg").on("click", function () {
        $("#" + modalId).modal("hide");
        if (settings.callbackCancel) {
          settings.callbackCancel();
        }
      });
    } else {
      bootbox.confirm({
        title: settings.title,
        message: message,
        callback: function (result) {
          if (result) {
            callback.call(this);
            $(".modal-backdrop").remove();
          }
          focusedElm.focus();
        },
      });

      setTimeout(function () {
        $(".bootbox-accept").trigger("focus");
      }, 500);
    }
  },

  showToast: function (heading, message, exp) {
    exp = $.extend(
      {
        position: "bottom-0 end-0",
      },
      exp || {}
    );

    var outerString =
      "\
        <div id='cpToast' class='position-fixed " +
      exp.position +
      " p-3' style='z-index: 5'>\
            <div class='toast-container'>\
            </div>\
        </div>\
        ";

    var toastId = Math.floor(100000 + Math.random() * 900000);
    var innerString =
      "\
        <div id='" +
      toastId +
      "' class='toast bg-light' role='alert' aria-live='assertive' aria-atomic='true'>\
            <div class='toast-header bg-primary'>\
                <strong class='me-auto'>" +
      heading +
      "</strong>\
                <button type='button' class='btn-close btn-close-white' data-bs-dismiss='toast' aria-label='Close'></button>\
            </div>\
            <div class='toast-body'>\
                " +
      message +
      " \
            </div>\
        </div>\
        ";

    // AS: REVIEW THIS LATER FOR STACKING
    if ($("#cpToast").length > 0) {
      $("#cpToast .toast-container").append(innerString);
    } else {
      $("body").append(outerString);
      $("#cpToast .toast-container").append(innerString);
    }

    $("#" + toastId).toast("show");
  },

  openModalForLink: function (exp) {
    if ($("#bsVersion").length > 0 && $("#bsVersion").val() >= 5) {
      Util.openModalForLinkBs5.call(this, exp);
    } else {
      exp = $.extend(
        {
          url: "",
          title: "",
          isJson: "",
          width: "",
          height: "",
          callbackFn: "",
          callbackOnOpen: "",
          callbackOnClose: "",
          showOuterOnly: false,
          showFormSubmit: false,
          submitFormLabel: "Save Changes",
          extraData: {},
        },
        exp || {}
      );

      var linkObj = $(this);
      var modalId = $(this).attr("id") ? $(this).attr("id") + "_modal" : "cpModal";

      url = exp.url ? exp.url : $(this).attr("url");
      if (!url) {
        url = $(this).attr("href");
      }

      if (Object.keys(exp.extraData).length > 0) {
        url += exp.extraData;
      }

      var title = exp.title ? exp.title : $(this).attr("title");
      var isJson = exp.isJson ? exp.isJson : $(this).attr("isJson");
      var callbackFn = exp.callbackFn ? exp.callbackFn : linkObj.attr("callbackFn");
      var callbackOnClose = exp.callbackOnClose ? exp.callbackOnClose : linkObj.attr("callbackOnClose");
      var width = exp.width ? exp.width : $(this).attr("w");
      var height = exp.height ? exp.height : $(this).attr("h");
      var showFormSubmit = exp.showFormSubmit ? exp.showFormSubmit : $(this).attr("showFormSubmit");
      var modalCls = exp.modalCls ? exp.modalCls : $(this).attr("modalCls");
      var submitFormLabel = $(this).attr("submitFormLabel") ? $(this).attr("submitFormLabel") : exp.submitFormLabel;

      // adds a scrollable class - cp-scroll
      var hasScrollBody = exp.hasScrollBody ? exp.hasScrollBody : $(this).attr("hasScrollBody");
      var scrollBodyClass = hasScrollBody ? "cp-scroll" : "";

      var dataType = isJson ? "json" : "html";

      var modalMainCls = "modal fade";

      if (modalCls) {
        // for backward compatibiliyy
        var modalMainCls = modalMainCls + " " + modalCls;
      }

      var showModal = function (data, status, xhr) {
        // Util.hideProgressInd();
        var htmlResponse = data;

        if (isJson) {
          htmlResponse = data.html;
        }

        var footerText = "";
        if (showFormSubmit) {
          footerText =
            "\
                    <div class='modal-footer'>\
                        <button type='button' class='btn btn-default closeBsFormModal' data-dismiss='modal'>Close</button>\
                        <button type='button' class='btn btn-primary submitBsFormModal'>" +
            submitFormLabel +
            "</button>\
                    </div>\
                    ";
        }

        if (exp.showOuterOnly) {
          var htmlString =
            "\
                    <div id='" +
            modalId +
            "' class='" +
            modalMainCls +
            "'>\
                        <div class='modal-dialog'>\
                            <div class='modal-content'>\
                                " +
            htmlResponse +
            "\
                            </div>\
                        </div>\
                    </div>\
                    ";
        } else {
          var htmlString =
            "\
                    <div id='" +
            modalId +
            "' class='" +
            modalMainCls +
            "' data-keyboard='false'>\
                        <div class='modal-dialog'>\
                            <div class='modal-content'>\
                                <div class='modal-header'>\
                                    <h4 class='modal-title'>" +
            title +
            "</h4>\
                                    <button type='button' class='close' data-dismiss='modal' \
                                        aria-hidden='true'><i class='fa fa-times-circle fa-lg'></i></button>\
                                </div>\
                                <div class='modal-body " +
            scrollBodyClass +
            "'>\
                                    " +
            htmlResponse +
            "\
                                </div>\
                                " +
            footerText +
            "\
                            </div>\
                        </div>\
                    </div>\
                    ";
        }

        if ($("#" + modalId).length > 0) {
          $("#" + modalId).remove();
        }
        var focusedElm = $(":focus");

        $("body").append(htmlString);

        $("#" + modalId).modal({
          keyboard: false,
          backdrop: "static",
        });

        $("#" + modalId).on("shown.bs.modal", function () {
          Util.hideProgressInd();
          $(".modal-body").scrollTop(0);
          if (exp.callbackOnOpen) {
            exp.callbackOnOpen.call(this);
          }
        });

        $("#" + modalId).on("hidden.bs.modal", function () {
          if (callbackOnClose) {
            var callbackOnCloseFnName = eval(callbackOnClose);
            callbackOnCloseFnName.call(this, linkObj, data);
          } else {
            $(this).remove();
          }
          focusedElm.focus();
        });

        var modalDlgObj = $("#" + modalId + " .modal-dialog");
        var modalBodyObj = $(".modal-body", modalDlgObj);
        if (!width) {
          width = parseInt(modalDlgObj.css("width"));
        } else {
          if (width == "screen") {
            width = $(document).width() - 200;
          }
        }

        if (width != 0 && width != undefined) {
          if (width > $(document).width()) {
            width = $(document).width() - 50;
          }

          width = $.isNumeric(width) ? width + "px" : width;

          modalDlgObj.css({
            width: width,
            "max-width": width,
            "margin-left": "auto",
            "margin-right": "auto",
          });
        }
        if (height) {
          //if a number is given for height then add a 'px' to it
          height = $.isNumeric(height) ? height + "px" : height;

          //if a percentage is used in height
          if (height.indexOf("%") !== -1) {
            var heightPerc = parseInt(height);
            var heightWindow = $(window).height();
            height = heightWindow * (heightPerc / 100);
          }

          modalBodyObj.css({
            height: height,
            "overflow-y": "scroll",
          });
        }

        if (callbackFn) {
          var callbackFnName = eval(callbackFn);
          callbackFnName.call(this, linkObj, data);
        }

        Util.hideProgressInd();
      }; //showModal function

      $.get(url, showModal, dataType);
    }
  },

  openModalForLinkBs5: function (exp) {
    // AS: THIS FUNCTIONS NEEDS TO BE FURTHER IMPROVISED
    Util.showProgressInd();
    exp = $.extend(
      {
        url: "",
        title: "",
        widthCls: "",
      },
      exp || {}
    );

    var linkObj = $(this);
    var modalId = $(this).attr("id") ? $(this).attr("id") + "_modal" : "cpModal";

    url = exp.url ? exp.url : $(this).attr("url");
    if (!url) {
      url = $(this).attr("href");
    }

    var title = exp.title ? exp.title : $(this).attr("title");
    var widthCls = exp.widthCls ? exp.widthCls : $(this).attr("widthCls");

    var showModal = function (data, status, xhr) {
      var htmlResponse = data;
      var footerText = "";

      var htmlString =
        "\
            <div id='" +
        modalId +
        "' class='modal'>\
                <div class='modal-dialog " +
        widthCls +
        "'>\
                    <div class='modal-content'>\
                        <div class='modal-header'>\
                            <h5 class='modal-title'>" +
        title +
        "</h5>\
                            <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>\
                        </div>\
                        <div class='modal-body'>\
                            " +
        htmlResponse +
        "\
                        </div>\
                        " +
        footerText +
        "\
                    </div>\
                </div>\
            </div>\
            ";

      if ($("#" + modalId).length > 0) {
        $("#" + modalId).remove();
      }

      $("body").append(htmlString);

      var myModalInstance = new bootstrap.Modal(document.getElementById(modalId), {});
      myModalInstance.show();
      Util.hideProgressInd();
    }; //showModal function

    $.get(url, showModal);
  },

  goToByScroll: function (id) {
    $("html,body").animate({ scrollTop: $("#" + id).offset().top }, "slow");
  },

  loadDropdownByJSON: function (srcFld, srcValue, dstFld, dstRoom, formId, exp) {
    var url = $("#scopeRootAlias").val() + "index.php?room=" + dstRoom + "&_spAction=jsonForDropdown&showHTML=0";

    if (!formId) {
      formId = "frmEdit";
    }

    exp = $.extend(
      {
        extraDestFlds: [], //for ex: we have category (source) and sub_category1, sub_category2, sub_category3 etc as destination fields
        srcValueType: "id", //id or label
        useKeyForDstFld: true,

        masterListName: "", //for ex: geo_area
        masterListSrcTbl: "",
        masterListSrcIdFld: "",
        masterListSrcLblFld: "",
      },
      exp || {}
    );
    var callbackFn = exp.callbackFn ? exp.callbackFn : "";

    Util.showProgressInd();
    var firstOptionText = $("#" + formId + " select#" + dstFld + " option:first").text();
    var loadingJSON = [{ value: "", caption: "Loading..." }];

    var dtsFldObj = $("#" + formId + " select#" + dstFld);

    dtsFldObj.cp_loadSelect(loadingJSON);
    for (var i = 0; i < exp.extraDestFlds.length; i++) {
      var dstFldExtra = exp.extraDestFlds[i];
      $("#" + formId + " select#" + dstFldExtra).cp_loadSelect(loadingJSON);
    }
    $.getJSON(
      url,
      {
        srcFld: srcFld,
        srcValue: srcValue,
        firstOptionText: firstOptionText,
        srcValueType: exp.srcValueType,
        useKeyForDstFld: exp.useKeyForDstFld,

        masterListName: exp.masterListName,
        masterListSrcTbl: exp.masterListSrcTbl,
        masterListSrcIdFld: exp.masterListSrcIdFld,
        masterListSrcQueryFld: exp.masterListSrcQueryFld,
      },
      function (json) {
        /*
            when selected value is passed, the returned data will be in 3D array
            the dropdown data will be in json.data  ex: wingwah/serviceplan
            $json = array(
                'selectedValue' => 1,
                'data' => $json
            );
            */
        data = json.selectedValue ? json.data : json;
        dtsFldObj.cp_loadSelect(data);
        for (var i = 0; i < exp.extraDestFlds.length; i++) {
          var dstFldExtra = exp.extraDestFlds[i];
          $("#" + formId + " select#" + dstFldExtra).cp_loadSelect(data);
        }

        if (json.selectedValue) {
          dtsFldObj.val(json.selectedValue);
        }

        if (callbackFn) {
          var callbackFnName = eval(callbackFn);
          callbackFnName.call(this);
        }

        Util.hideProgressInd();

        //if boostrap-select is used (ddbs) then refresh the related values
        if ($("#" + dstFld).hasClass("cp-selectpicker")) {
          setTimeout(function () {
            $("#" + dstFld).selectpicker("refresh");
          });
        }
      }
    );
  },

  showTypeaheadDropdown: function () {
    var txtFldObj = $(this);
    var fldName = txtFldObj.attr("name");
    var dispFld = txtFldObj.attr("dispFld");
    var valFld = txtFldObj.attr("valFld");
    var useFts = txtFldObj.attr("useFts"); // mysql full text search
    var searchFlds = txtFldObj.attr("searchFlds");
    var module = txtFldObj.attr("module");
    var callback = txtFldObj.attr("callback");
    var calledFrom = txtFldObj.attr("calledFrom");
    var parentRecIdForChild = txtFldObj.attr("parentRecIdForChild"); // used in 3 level linking
    var calledFromRecId = $("#record_id").val();

    var room = module ? module : $("#cpRoom").val();
    var dispFld = dispFld ? dispFld : fldName;
    var valFld = valFld ? valFld : fldName;
    var searchFlds = searchFlds ? searchFlds : dispFld;
    var useFts = useFts ? useFts : 0;

    var remoteUrl = "index.php?_spAction=jsonForAutoSuggest";
    txtFldObj.typeahead({
      ajax: {
        url: remoteUrl,
        preDispatch: function (query) {
          return {
            query: query,
            room: room,
            calledFrom: calledFrom,
            dispFld: dispFld,
            valFld: valFld,
            searchFlds: searchFlds,
            useFts: useFts,
            calledFromRecId: calledFromRecId,
          };
        },
      },
      items: 50,
      onSelect: function (item) {
        // if from the link panel
        var selectedId = item.value;
        if (fldName == "linkSrcDataSrch") {
          // txtFldObj.attr('value', '');
          var srcRoomRecId = $("#record_id").val();
          var wrapper = txtFldObj.closest(".linkSrcDataWrapper");
          var srcDataObj = $("#srcDataTbl", wrapper);
          var resultUrl = txtFldObj.attr("resultUrl");
          $.post(resultUrl, { srchRecId: selectedId, srcRoomRecId: srcRoomRecId }, function (html) {
            srcDataObj.hide();
            srcDataObj.html(html);
            srcDataObj.slideDown();
          });
        } else if (fldName == "addItemBySrch") {
          // txtFldObj.attr('value', '');
          var srcRoomRecId = $("#record_id").val();
          var wrapper = txtFldObj.closest(".linkPortalWrapper");
          var linkName = wrapper.attr("id");
          var lnkRoomActual = wrapper.attr("lnkRoomActual");
          var url = txtFldObj.attr("addItemUrl") + "&record_id=" + srcRoomRecId + "&lnkRoomId=" + selectedId;
          if (parentRecIdForChild) {
            url += "&parentRecIdForChild=" + parentRecIdForChild;
          }

          var useNgGrid = txtFldObj.attr("useNgGrid");

          var exp = {
            portalDiv: wrapper,
            goToLastPage: true,
          };
          txtFldObj.val("");
          $.post(url, function (data) {
            var linkName = wrapper.attr("linkName");
            if (useNgGrid) {
              var gridTable = $(".grid", wrapper);
              //add the row to the scope array variable and trigger save
              var $body = angular.element($("body"));
              var $scope = $body.scope();
              var $rootScope = $scope.$root;

              var $timeout = $body.injector().get("$timeout");
              $rootScope.$apply(function () {
                $rootScope.cpLinkUtil.addToGridArray(gridTable, data, $scope, linkName);
                $timeout(function () {
                  $rootScope.cpLinkUtil.saveGridRowManually(gridTable, data, $scope, linkName);
                });
              });
            } else {
              Links.reloadPortalRecords(linkName, lnkRoomActual, srcRoomRecId, "edit", exp);
            }
          });
        } else {
          if (callback) {
            var callbackFnName = eval(callback);
            callbackFnName.call(this, selectedId);
          }
        }
      },
    });
  },

  unique: function (arr) {
    var result = [];
    $.each(arr, function (i, e) {
      if ($.inArray(e, result) == -1) result.push(e);
    });
    return result;
  },

  addCommasToNumber: function (nStr) {
    nStr += "";
    x = nStr.split(".");
    x1 = x[0];
    x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  },

  getUrlParam: function (name) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var value = urlParams.get(name);
    value = value === null ? "" : value;

    return value;
  },

  getUrlParams: function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    entries = urlParams.entries();
    return entries;
  },

  removeURLParam: function (url, key) {
    url = url.toString();
    var urlParts = url.split("?");
    var params = new URLSearchParams(urlParts[1]);
    params.delete(key);
    var newUrl = urlParts[0] + "?" + params.toString();

    return newUrl;
  },
};

//------------------------------------------------//
var Forms = {
  setupBsForm: function (frmId) {
    frmObj = $("#" + frmId);
    var showProgBar = frmObj.attr("showProgBar");
    $(frmObj)
      .find("input,select,textarea")
      .not("[type=submit]")
      .jqBootstrapValidation({
        submitSuccess: function ($form, event) {
          // will not trigger the default submission in favor of the ajax function
          event.preventDefault();

          if (showProgBar != 0) {
            Util.showProgressInd();
          }
          var cpCSRFToken = $("#cpCSRFToken").val();
          var postArr = $form.serializeObject();
          postArr["cpCSRFToken"] = cpCSRFToken;

          $(".cp-tinymce").each(function (index, editor) {
            var editorId = editor.id;
            var editorName = editor.name;
            if (editorId) {
              postArr[editorName] = tinymce.get(editorId).getContent();
            }
          });

          //if angularjs is loaded then submit the scope values as well
          if (typeof angular !== "undefined") {
            var ngModels = $("[ng-model]", $form);
            if (ngModels.length > 0) {
              var scope = angular.element($(ngModels[0])).scope();
              _.each(ngModels, function (ngModelFld) {
                var modelName = $(ngModelFld).attr("ng-model");
                postArr[modelName] = scope[modelName];
              });
            }
          }

          $.ajax({
            type: "POST",
            url: $form.attr("action"),
            data: postArr,
            dataType: "json",
            success: function (json) {
              Forms.validateBsForm($form, json);
            },
          });
        },
      });
  },

  setupJqForm: function (frmId) {
    frmObj = $("#" + frmId);
    var showProgBar = frmObj.attr("showProgBar");

    $(frmObj).submit(function (e) {
      e.preventDefault();
      Util.showProgressInd();
      var form = $(this);
      var cpCSRFToken = $("#cpCSRFToken").val();
      var postArr = form.find("input,select,textarea").serializeObject();
      postArr["cpCSRFToken"] = cpCSRFToken;

      //if angularjs is loaded then submit the scope values as well
      if (typeof angular !== "undefined") {
        var ngModels = $("[ng-model]", form);
        if (ngModels.length > 0) {
          var scope = angular.element($(ngModels[0])).scope();
          _.each(ngModels, function (ngModelFld) {
            var modelName = $(ngModelFld).attr("ng-model");
            postArr[modelName] = scope[modelName];
          });
        }
      }

      var formData = new FormData(form[0]);
      for (var key in postArr) {
        formData.append(key, postArr[key]);
      }

      // Attach file
      var files = $("input[type=file]");
      for (var key in files) {
        //to avoid context:, length:, prevObject:, selector: etc properties
        if ($.isNumeric(key)) {
          var fileElement = files[key];
          formData.append(fileElement.name, fileElement.files[0]);
        }
      }

      $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: formData,
        dataType: "json",
        processData: false,
        contentType: false,
        success: function (json) {
          Forms.validateBsForm(form, json);
        },
        error: function (json) {
          alert("error...");
        },
      });
    });
  },

  validateBsForm: function (frmObj, json) {
    if (json.errorCount && json.errorCount > 0) {
      Util.hideProgressInd();
      $("input[name=frmValidated]", frmObj).val(0);
      Forms.alertErrorsText(frmObj, json);

      $(".reloadCaptcha", frmObj).click();

      if ($(".g-recaptcha", frmObj).length > 0) {
        grecaptcha.reset();
      }
    } else if (json.errorCount == 0) {
      $("input[name=frmValidated]", frmObj).val(1);
      var dontClearAfterSubmit = frmObj.attr("dontClearAfterSubmit");
      var returnUrlFld = $("input[name=returnUrl]", frmObj);

      if (dontClearAfterSubmit !== "1") {
        frmObj.clearForm();
      }

      var refresh = $("[name=refresh]", frmObj).val();
      refresh = typeof refresh === "undefined" ? 1 : refresh;
      if (refresh == 0) {
        Forms.alertSuccessText(frmObj, json);
        Util.hideProgressInd();
      } else {
        if (json.returnUrl && json.returnUrl != "") {
          frmObj.closest(".modal").hide(".modal");
          document.location = json.returnUrl;
        } else if (returnUrlFld.length != 0 && returnUrlFld.val() != "") {
          document.location = returnUrlFld.val();
        } else if (json.callback) {
          Util.hideProgressInd();
          var callbackFnName = eval(json.callback);
          callbackFnName.call(this, json);
          frmObj.closest(".modal").hide(".modal");
          $("body").removeClass("modal-open");
          $(".modal-backdrop").remove();
        } else {
          Forms.alertSuccessText(frmObj, json);
          Util.hideProgressInd();
        }
      }

      $(".reloadCaptcha", frmObj).click();
    } else {
    }
  },

  alertErrorsText: function (frmObj, json) {
    $(".alert-block", frmObj).hide();
    var callbackFnFld = $("input[name=callbackFnOnError]", frmObj);
    var showError = $("input[name=showError]", frmObj).val();

    if (!showError) {
      showError = 1;
    }

    errorText = `<h4 class='alert-heading'>
          ${Lang.get("cp_form_errorTitle", "The following error(s) occurred")}</h4><br>`;

    $(frmObj).find("input, textarea").removeClass("is-invalid");

    $.each(json.errors, function (fldName) {
      var urlHref = "#fld_" + this.name;

      var text = "";
      if ($(urlHref)) {
        text = this.msg;
      } else {
        text = "<a href='" + urlHref + "'>" + this.msg + "</a>";
      }

      var fldNameId = "fld_" + fldName;
      if (this.msg) {
        var arrow = "<i class='fa fa-arrow-right' aria-hidden='true'></i> ";
        errorText += "<p><label for='" + fldNameId + "'>" + arrow + text + "</label></p>";
      }

      var fldObj = $("#" + fldNameId);
      fldObj.addClass("is-invalid");
    });

    if ($("#bsVersion").length > 0 && $("#bsVersion").val() >= 5) {
      var htmlString =
        "\
            <div class='alert alert-danger alert-dismissible fade show' role='alert'>\
            " +
        errorText +
        "\
            <button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>\
            </div>\
            ";
    } else {
      var htmlString =
        "\
            <div class='alert alert-danger alert-dismissable fade show in validation-errors'>\
                <button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button>\
                " +
        errorText +
        "\
            </div>\
            ";
    }

    if ($(callbackFnFld).length > 0) {
      if (callbackFnFld.val() != "") {
        var callbackFn = eval(callbackFnFld.val());
        callbackFn.call(this, json, frmObj);
      }
    }

    if (showError) {
      var frmId = frmObj.attr("id");
      $(".alert.alert-danger", frmObj).remove();
      frmObj.prepend(htmlString);

      //if the form is on a modal
      if ($("#" + frmId).closest(".modal").length > 0) {
        $(".modal").animate(
          {
            scrollTop: 0,
          },
          "slow"
        );
      } else {
        $("html,body").animate(
          {
            scrollTop: $("#" + frmId).offset().top - 50,
          },
          "slow"
        );
      }
    }
  },

  alertSuccessText: function (frmObj, json) {
    $(".alert", frmObj).hide();
    var successMsgFld = $("input[name=successMsg]", frmObj);
    var dialogMsgFld = $("input[name=dialogMsg]", frmObj);
    var callbackFnFld = $("input[name=callbackFn]", frmObj);
    var extraInfoForCallback = $("input[name=extraInfoForCallback]", frmObj);
    var hideFormOnSuccess = frmObj.attr("hideFormOnSuccess");

    if ($(dialogMsgFld).length > 0) {
      Util.closeAllModals();
      Util.alert(dialogMsgFld.val());
    }

    if ($(successMsgFld).length > 0) {
      var msg = $(successMsgFld).val();
      var htmlString =
        "\
            <div class='alert alert-success alert-dismissable show fade in'>\
                <button type='button' class='btn-close close' data-dismiss='alert' data-bs-dismiss='alert' aria-label='Close'><span>&times;</span></button>\
                " +
        msg +
        "\
            </div>\
            ";

      if (hideFormOnSuccess) {
        frmObj.html(htmlString);
      } else {
        if (successMsgFld.attr("position") == "bottom") {
          frmObj.append(htmlString);
        } else {
          frmObj.prepend(htmlString);
        }
      }
      Util.goToByScroll(frmObj.attr("id"));
    }

    var apply = $("input[name=apply]").val();
    var refresh = $("input[name=refresh]").val();
    if ($(callbackFnFld).length > 0) {
      if (callbackFnFld.val() != "") {
        var extraInfo = extraInfoForCallback.val();
        var callbackFn = eval(callbackFnFld.val());
        callbackFn.call(this, json, frmObj);
      }
    } else if (apply == 1 && refresh == 1) {
      var url = document.location.toString();
      //if hash from boostrap tab is applied in the url then no need to reload
      if (!url.match("#")) {
        document.location = url;
      } else {
        document.location.reload();
      }
    }
  },

  submitQuickSearchForm: function (event) {
    event.preventDefault();

    var $form = $(this);
    var postArr = $form.serializeObject();
    postArr = Forms.getNgModelsInFrom($form, postArr);

    var url = $form.attr("action") + "?" + $.param(postArr);
    document.location = url;
  },

  getNgModelsInFrom: function ($form, postArr) {
    var postArr = $form.serializeObject();

    //if angularjs is loaded then submit the scope values as well
    if (typeof angular !== "undefined") {
      var ngModels = $("[ng-model]", $form);
      if (ngModels.length > 0) {
        var scope = angular.element($(ngModels[0])).scope();
        _.each(ngModels, function (ngModelFld) {
          var modelName = $(ngModelFld).attr("ng-model");
          var modelValue = scope[modelName];
          postArr[modelName] = modelValue;
        });
      }
    }

    return postArr;
  },
}; // Forms = {}

//------------------------------------------------//
var Lang = {
  get: function (key, defaultVal) {
    defaultVal = defaultVal ? defaultVal : key;
    var data = "";
    if (typeof Lang.data !== "undefined") {
      data = Lang.data[key] ? Lang.data[key] : defaultVal;
    } else {
      data = defaultVal;
    }
    return data;
  },
};

//------------------------------------------------//
var Cfg = {
  get: function (key) {
    return Cfg.data[key];
  },
};

//------ Called on load ready -----//
$(function () {
  $("form.bsForm").livequery(function () {
    var formId = $(this).attr("id");
    Forms.setupBsForm(formId);
  });

  $("form.cpJqForm").livequery(function () {
    var formId = $("form.cpJqForm").attr("id");
    Forms.setupJqForm(formId);
  });

  // top search forms in the www
  $("form#searchTop select").change(function () {
    $('#searchTop').submit();
  });

  $(".reloadCaptcha").livequery("click", function (e) {
    e.preventDefault();
    var captchaId = $(this).attr("captcha_id");
    var captchaCode = $(this).parents("form").find("input[name=captcha_code]");
    captchaCode.val("");

    reloadUrl = $("#libraryPathAlias").val() + "lib_php/securimage/securimage_show.php?" + Math.random();
    $("#" + captchaId).attr("src", reloadUrl);
  });

  //quick search bar
  $("form.quick-search-form").submit(Forms.submitQuickSearchForm);

  $(document).on("click", ".cpModal", function (e) {
    e.preventDefault();
    e.stopPropagation();
    Util.openModalForLink.call(this);
  });

  $(".cpVideoModal").livequery(function () {
    $(".cpVideoModal").on("shown.bs.modal", function (event) {
      modalObj = $(this);
      var btnObj = $(event.relatedTarget);
      var vidSrc = btnObj.attr("vidSrc");
      var vidObj = $("#videoInModal")[0];

      if (vidSrc) {
        var source = document.createElement("source");
        source.setAttribute("src", vidSrc);
        vidObj.appendChild(source);
        vidObj.play();
      }
    });

    $(".cpVideoModal").on("hidden.bs.modal", function () {
      modalObj = $(this);
      var vidObj = $("#videoInModal")[0];
      vidObj.pause();
      $("source", vidObj).attr("src", "");
      vidObj.load();
    });
  });

  $.fn.clearForm = function () {
    return this.each(function () {
      var type = this.type,
        tag = this.tagName.toLowerCase();
      if (tag == "form") return $(":input", this).clearForm();
      if (type == "text" || type == "password" || type == "textarea" || type == "email") this.value = "";
      else if (type == "checkbox" || type == "radio") this.checked = false;
      else if (type == "select") this.selectedIndex = -1;
    });
  };

  //form time picker (bootstrap-datetimepicker plugin)
  if ($(".cpDateTimepicker").length > 0) {
    $(".cpDateTimepicker").datetimepicker({});
  }

  // $(".cpDatepicker").datetimepicker();

  $(".cpDatepicker").livequery(function () {
    var defaultDate = $(this).attr("defaultDate");
    var pickTime = $(this).attr("pickTime") == 1 ? true : false;

    $(".cpDatepicker")
      .datetimepicker({
        defaultDate: defaultDate,
        showClear: true,
        showClose: true,
        keepOpen: false,
      })
      .on("dp.change", function (e) {
        var wrapper = $(this).closest(".linkPortalDataWrapper");
        if (wrapper.length > 0) {
          Links.updateGridData.call($(this));
        }
        //$(this) is a div
        $(this).find("input").trigger("input");
      });

  });

  $("input.cpTypeahead").livequery(function () {
    Util.showTypeaheadDropdown.call($(this));
  });

  $("a.clearTypeahead").livequery("click", function (e) {
    e.preventDefault();
    var parent = $(this).closest(".linkSrcDataWrapper");
    $("input.cpTypeahead", parent).val("");
    $("#srcDataTbl table", parent).remove();
    $("ul.typeahead", parent).empty();
  });

  $(".cpTimepicker").livequery(function () {
    $(".cpTimepicker").datetimepicker({
      pickDate: false,
    });
  });

  $(".submitBsForm").livequery("click", function (e) {
    e.preventDefault();

    var frmId = $(this).attr("frmId");
    if (frmId) {
      $("#" + frmId).submit();
    } else {
      // for backward compatibilty
      $(this).closest("form.bsForm").submit();
    }
  });

  $(".submitBsFormModal").livequery("click", function (e) {
    e.preventDefault();
    $("form.cpJqForm", $(this).closest(".modal")).submit();
  });

  $(".cpBack").on("click", function (e) {
    e.preventDefault();
    history.back();
  });

  if ($(".fancybox").length > 0) {
    $(".fancybox").fancybox({});
  }

  if ($(".footable").length > 0) {
    $(".footable").footable({});
  }

  if ($("a.cpZoom").length > 0) {
    $("a.cpZoom").colorbox({ maxHeight: $(window).height(), maxWidth: $(window).width() });
  }

  $("a[rel='cpZoomGallery']").livequery(function () {
    $("a[rel='cpZoomGallery']").colorbox({ maxHeight: $(window).height(), maxWidth: $(window).width() });
  });

  $(".panel-heading.clickable").livequery("click", function (e) {
    var $this = $(this);
    if (!$this.hasClass("panel-collapsed")) {
      $this.closest(".panel").find(".panel-body").hide();
      $this.addClass("panel-collapsed");
      $this.find("i").removeClass("glyphicon-chevron-up").addClass("glyphicon-chevron-down");
    } else {
      $this.closest(".panel").find(".panel-body").show();
      $this.removeClass("panel-collapsed");
      $this.find("i").removeClass("glyphicon-chevron-down").addClass("glyphicon-chevron-up");
      // $this.next().slideDown();
      // $this.removeClass('panel-collapsed');
      // $this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
    }
  });

  $(".panel-heading.clickable").livequery(function () {
    var $this = $(this);
    if ($this.hasClass("panel-collapsed")) {
      $this.closest(".panel").find(".panel-body").hide();
    }
  });

  $(".clearDate").livequery(function () {
    $(this).click(function () {
      $("input", $(this).closest(".cpDatepicker")).val("");
    });
  });

  $(".cpShowPassword").livequery(function () {
    $(this).click(function () {
      var $pwd = $("input", $(this).closest(".input-group"));
      if ($pwd.attr("type") === "password") {
        $pwd.attr("type", "text");
      } else {
        $pwd.attr("type", "password");
      }
    });
  });

  $(".cp-selectpicker").livequery(function () {
    $(this).selectpicker({
      selectOnTab: true,
    });

    $(this).on("changed.bs.select", function (e, clickedIndex, isSelected, previousValue) {
      var selectObj = this;
      // $('.cp-selectpicker').selectpicker('refresh');
      // $(selectObj).selectpicker('val', 'RMB');

      // clear any related select fld
      var relatedSelectFld = $(selectObj).attr("related-select-fld");
      if (relatedSelectFld) {
        var relatedSelectFldObj = $("#fld_" + relatedSelectFld);
        relatedSelectFldObj.val("");
      }

      // move to the next form object (div.form-group) instead of staying in the same object
      // for ex: in quick search panel so that it's easy to submit by pressing enter
      var nextDiv = $(selectObj).closest(".form-group").nextAll(":visible").first();
      nextDiv.find("input, select, textarea, .dropdown-toggle").focus();
    });
  });
});

(function ($) {
  // VERTICALLY ALIGN FUNCTION
  $.fn.vAlign = function (opts) {
    opts = opts || {};

    var defaults = {
      marginTopDiff: 0,
    };
    opts = $.extend({}, defaults, opts);

    return this.each(function (i) {
      opts.marginTopDiff;
      var ah = $(this)[0].height;
      var ph = $(this).parent().height();
      var mh = Math.ceil((ph - ah) / 2);
      mh = mh + opts.marginTopDiff;
      $(this).animate(
        {
          "padding-top": mh,
        },
        "slow"
      );
    });
  };
})(jQuery);

(function ($) {
  $.fn.cp_emptySelect = function () {
    return this.each(function () {
      if (this.tagName == "SELECT") this.options.length = 0;
    });
  };

  $.fn.cp_loadSelect = function (optionsDataArray) {
    return this.cp_emptySelect().each(function () {
      if (this.tagName == "SELECT") {
        var selectElement = this;
        $.each(optionsDataArray, function (index, optionData) {
          var option = new Option(optionData.caption, optionData.value);
          // if ($.browser.msie) {
          //   selectElement.add(option);
          // } else {
          //   selectElement.add(option,null);
          // }
          selectElement.add(option, null);
        });
      }
    });
  };
})(jQuery);

(function ($) {
  $.fn.extend({
    center: function (options) {
      var options = $.extend(
        {
          // Default values
          inside: window, // element, center into window
          transition: 0, // millisecond, transition time
          minX: 0, // pixel, minimum left element value
          minY: 0, // pixel, minimum top element value
          withScrolling: true, // booleen, take care of the scrollbar (scrollTop)
          vertical: true, // booleen, center vertical
          horizontal: true, // booleen, center horizontal
        },
        options
      );
      return this.each(function () {
        var props = { position: "absolute" };
        if (options.vertical) {
          var top = ($(options.inside).height() - $(this).outerHeight()) / 2;
          if (options.withScrolling) top += $(options.inside).scrollTop() || 0;
          top = top > options.minY ? top : options.minY;
          $.extend(props, { top: top + "px" });
        }
        if (options.horizontal) {
          var left = ($(options.inside).width() - $(this).outerWidth()) / 2;
          if (options.withScrolling) left += $(options.inside).scrollLeft() || 0;
          left = left > options.minX ? left : options.minX;
          $.extend(props, { left: left + "px" });
        }
        if (options.transition > 0) $(this).animate(props, options.transition);
        else $(this).css(props);
        return $(this);
      });
    },
  });

  //serialize object
  $.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || "");
      } else {
        o[this.name] = this.value || "";
      }
    });
    return o;
  };
})(jQuery);

// used for incrementing decrementing numbers in macro
(function ($) {
  $(".btn-number-incDec").livequery("click", function (e) {
    e.preventDefault();

    fieldName = $(this).attr("data-field");
    type = $(this).attr("data-type");
    var input = $("input[name='" + fieldName + "']");
    var currentVal = parseFloat(input.val());
    var increment = parseFloat(input.attr("increment"));

    if (!isNaN(currentVal)) {
      if (type == "minus") {
        if (currentVal > input.attr("min")) {
          input.val(currentVal - increment).change();
        }
        if (parseInt(input.val()) == input.attr("min")) {
          $(this).attr("disabled", true);
        }
      } else if (type == "plus") {
        if (currentVal < input.attr("max")) {
          input.val(currentVal + increment).change();
        }
        if (parseInt(input.val()) == input.attr("max")) {
          $(this).attr("disabled", true);
        }
      }
    } else {
      input.val(0);
    }
  });
  $(".input-number-incDec").livequery("focusin", function () {
    $(this).data("oldValue", $(this).val());
  });
  $(".input-number-incDec").livequery("change", function () {
    minValue = parseInt($(this).attr("min"));
    maxValue = parseInt($(this).attr("max"));
    valueCurrent = parseInt($(this).val());

    name = $(this).attr("name");
    if (valueCurrent >= minValue) {
      $(".btn-number-incDec[data-type='minus'][data-field='" + name + "']").removeAttr("disabled");
    } else {
      alert("Sorry, the minimum value was reached");
      $(this).val($(this).data("oldValue"));
    }
    if (valueCurrent <= maxValue) {
      $(".btn-number-incDec[data-type='plus'][data-field='" + name + "']").removeAttr("disabled");
    } else {
      alert("Sorry, the maximum value was reached");
      $(this).val($(this).data("oldValue"));
    }
  });
  $(".input-number-incDec").livequery("keydown", function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  });
})(jQuery);

// cpAutosized jquery plugin
(function ($) {
  $.fn.cpAutosized = function (options) {
    var elem = $(this);
    // elem.on("change keyup keydown paste cut input click", function(e) {
    elem.on("change keyup keydown paste cut input click", function (e) {
      var targetElem = $(e.target);
      targetElem.height(0);
      var scrollHeight = targetElem.prop("scrollHeight");
      targetElem.height(scrollHeight);
    });

    // var settings = $.extend(
    //     {
    //         resize: "none",
    //         width: "",
    //         overflow: "",
    //         minHeight: "",
    //         backgroundColor: "",
    //         color: "",
    //         maxHeight: ""
    //     },
    //     options
    // );

    // var maxHeight = settings.maxHeight;
    // if (elem.attr('cp-max-height')) {
    //     maxHeight = elem.attr('cp-max-height');
    // }

    // return this.css({
    //     resize: settings.resize,
    //     width: settings.width,
    //     overflow: settings.overflow,
    //     minHeight: settings.minHeight,
    //     maxHeight: maxHeight,
    //     backgroundColor: settings.backgroundColor,
    //     color: settings.color
    // });
  };
})(jQuery);

(function ($) {
  $.fn.countTo = function (options) {
    options = options || {};

    return $(this).each(function () {
      // set options for current element
      var settings = $.extend(
        {},
        $.fn.countTo.defaults,
        {
          from: $(this).data("from"),
          to: $(this).data("to"),
          speed: $(this).data("speed"),
          refreshInterval: $(this).data("refresh-interval"),
          decimals: $(this).data("decimals"),
        },
        options
      );

      // how many times to update the value, and how much to increment the value on each update
      var loops = Math.ceil(settings.speed / settings.refreshInterval),
        increment = (settings.to - settings.from) / loops;

      // references & variables that will change with each update
      var self = this,
        $self = $(this),
        loopCount = 0,
        value = settings.from,
        data = $self.data("countTo") || {};

      $self.data("countTo", data);

      // if an existing interval can be found, clear it first
      if (data.interval) {
        clearInterval(data.interval);
      }
      data.interval = setInterval(updateTimer, settings.refreshInterval);

      // initialize the element with the starting value
      render(value);

      function updateTimer() {
        value += increment;
        loopCount++;

        render(value);

        if (typeof settings.onUpdate == "function") {
          settings.onUpdate.call(self, value);
        }

        if (loopCount >= loops) {
          // remove the interval
          $self.removeData("countTo");
          clearInterval(data.interval);
          value = settings.to;

          if (typeof settings.onComplete == "function") {
            settings.onComplete.call(self, value);
          }
        }
      }

      function render(value) {
        var formattedValue = settings.formatter.call(self, value, settings);
        $self.html(formattedValue);
      }
    });
  };

  $.fn.countTo.defaults = {
    from: 0, // the number the element should start at
    to: 0, // the number the element should end at
    speed: 1000, // how long it should take to count between the target numbers
    refreshInterval: 100, // how often the element should be updated
    decimals: 0, // the number of decimal places to show
    formatter: formatter, // handler for formatting the value before rendering
    onUpdate: null, // callback method for every time the element is updated
    onComplete: null, // callback method for when the element finishes updating
  };

  function formatter(value, settings) {
    return value.toFixed(settings.decimals);
  }
})(jQuery);
