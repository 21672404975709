Util.createCPObject('cpm.general.contact');

cpm.general.contact = {
    init: function(){
        $('.addTplRow').on('click', function(e){
            e.preventDefault();
            var wrapper = $(this).closest('div[rowTpl]');
            var tplRowId = wrapper.attr('rowTpl');
            wrapper.append($('#' + tplRowId).html());
        });

        $('#contactForm .removeRow').livequery('click', function() {
          $(this).closest('.form-group').remove();
        });

        // $(document).on('click', '.removeRow', function(e){
        //     $(this).closest('.form-group').remove();
        // });

        $('#download-pdf').click(function(){
            var url = "/download-membership-renewal";
            document.location = url;
        });

        $('input[name=payment_method]').click(function(){
            var value = $(this).val();
            if(value == "Stripe"){
                $('.creditCardInfo').show();
                $('.paypalInfo').hide();
            } else if(value == "ElasticEngine"){
                $('.creditCardInfo').show();
                $('.paypalInfo').hide();
            } else if(value == "Paypal") {
                $('.creditCardInfo').hide();
                $('.paypalInfo').show();
            } else {
                $('.creditCardInfo').hide();
                $('.paypalInfo').hide();
            }
        }).click();

        $('#contactForm.step2 input, #contactForm.step2 select, #contactForm.step2 textarea').livequery('change', function(e) {
            var fldName = $(this).attr('name');

            var formContent = $("[name='" + fldName + "']").serialize();
            var url = "/index.php?module=general_gateway&_spAction=save&showHTML=0";

            $.post(url, formContent, function(){
            });
        });

        if ($('.custDatePicker.dob').length > 0){
            $('.custDatePicker.dob').datepick({
                dateFormat: 'yyyy-mm-dd',
                yearRange: '1901:-20'
            });
        }

        if ($('.practiceArea').length > 0){
            $('.practiceArea, .backgroundInfo').readmore({
                'maxHeight' : 70
            });
        }

        $('.viewMyProfile #editProfile :input').attr('disabled', true);
        $('.viewMyProfile .cpDatepicker span').hide();

        $(document).on('click', '.btnShowAdvSearch, input[name=keyword]', function(e){
            var obj = $(this);

            obj.tooltip('hide');
            var panel = $('.advSearchPanel', $(this).closest('form'));
            var span = $('span', obj);

            if (panel.is(":visible")){
                span.addClass('fa-caret-down').removeClass('fa-times');
                panel.hide();
            } else {
                span.addClass('fa-times').removeClass('fa-caret-down');
                panel.show();
            }
        });

        $('.btnShowAdvSearch').livequery(function(){
            $('.btnShowAdvSearch').tooltip();
        });
    },

    cbAfterRenewal: function(){
        var msg = 'Your renewal has been submitted successfully.<br><br>We will process your request and send a confirmation email.';
        Util.alert(msg, function(){
            document.location = $('#returnUrl').val();
        });
    },

    cbAfterEditProfile: function(){
        var msg = 'Your profile has been submitted successfully.';
        Util.alert(msg, function(){
            document.location = $('#returnUrl').val();
        });
    }
};

