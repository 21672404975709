Util.createCPObject('cpm.ipba.internalEvent');

cpm.ipba.internalEvent = {
    init: function(){
        $('#calendar').fullCalendar({
            events: {
                url: '/index.php?module=ipba_internalEvent&_spAction=calendarData',
            }
        });

        $('.btnAddToCart').livequery('click', function(e){
            cpm.ipba.internalEvent.addToCart.call(this, e);
        })

        $('.btnRemoveFromList').livequery('click',function(e){
            cpm.ipba.internalEvent.removeItemFromList.call(this, e);
        })

        $('.btnAddToEvent').livequery('click', function(e){
            cpm.ipba.internalEvent.addToEvent.call(this, e);
        })

        $('.btnRemoveFromEvent').livequery('click',function(e){
            cpm.ipba.internalEvent.removeItemFromEvent.call(this, e);
        })

    },

    addToCart: function(e){
        e.preventDefault();
        Util.showProgressInd();
        var wrapper = $(this).closest('td');
        if (cpt.generic.isLoggedIn()){
            var itemId = $(this).closest('tr').attr('itemId');
            var url = '/index.php?module=ecommerce_basket&_spAction=addToCart';
            $.getJSON(url, {itemId: itemId}, function(json){
                wrapper.html($('#viewCartBtnTpl').html());
                $('.btnRemoveFromList', wrapper).attr('basketId', json.basketId);
                Util.hideProgressInd();
            });
        }
    },


    addToEvent: function(e){
        e.preventDefault();
        Util.showProgressInd();
        var wrapper = $(this).closest('td');
        if (cpt.generic.isLoggedIn()){
            var itemId = $(this).closest('tr').attr('itemId');
            var url = '/index.php?module=ecommerce_basket&_spAction=addToEvent';
            $.getJSON(url, {itemId: itemId}, function(json){
                wrapper.html($('#removeFromEventTpl').html());
                // $('.btnRemoveFromList', wrapper).attr('historyId', json.historyId);
                Util.hideProgressInd();
            });
        }
    },

    removeItemFromList: function(e){
        Util.showProgressInd();
        e.preventDefault();
        Util.showProgressInd();
        var basketId = $(this).attr('basketId');
        var wrapper = $(this).closest('td');
        var url = '/index.php?module=ecommerce_basket&_spAction=removeFromCart';
        $.get(url, {basketId: basketId}, function(){
            wrapper.html($('#removeFromEventTpl').html());
            Util.hideProgressInd();
        });
    },

    removeItemFromEvent: function(e){
        Util.showProgressInd();
        e.preventDefault();
        Util.showProgressInd();
        var itemId = $(this).closest('tr').attr('itemId');
        var wrapper = $(this).closest('td');
        var url = '/index.php?module=ecommerce_basket&_spAction=removeFromEvent';
        $.get(url, {itemId: itemId}, function(){
            wrapper.html($('#addToEventBtnTpl').html());
            Util.hideProgressInd();
        });
    }
}
