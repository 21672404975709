Util.createCPObject('cpm.ipba.event');

cpm.ipba.event = {
    init: function() {
        
    }
};
$(document).ready(function(){
    $(document).on('click', 'input[type=radio][name=ipba_member]', function(e){
        var event_fee = $('#event_fee').val();
        var non_member_event_fee = $('#non_member_event_fee').val();
        
        if($(this).val() == true){ // assuming 'false' is the value you're checking for
            $('#fld_non_member_fee').text(event_fee);
        } else {
            $('#fld_non_member_fee').text(non_member_event_fee);
        }
    });
});
